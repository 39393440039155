@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

body{
  background: #f9f9f9;
  background-image: url('./images/bg.png');
  background-size: 350px;
  background-position: center;
}

.container{
  width: 95%;
  max-width: 1024px;
  margin: 30px auto;
}